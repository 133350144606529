import {Component, Inject, OnDestroy, OnInit, AfterViewInit} from '@angular/core';
import {FormGroup, Validators, FormBuilder, FormControl} from '@angular/forms';
import {HttpClient} from '@angular/common/http';
import {Router, ActivatedRoute} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {first} from 'rxjs/operators';
import {ApiService, AuthService, LangService, TrackingService} from '../services/service.index';
import {DOCUMENT} from '@angular/common';
import {WINDOW} from "../services/window.service";
import {ToastrService} from 'ngx-toastr';
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ReCaptchaV3Service} from '../libs/ngx-captcha/services/recaptcha_v3.service';

/**
 * Login Component
 *
 * @author Fran Aragon
 */
@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, OnDestroy, AfterViewInit {

    /**
     * Using by form
     */
    public keyForm: FormGroup;
    private hash: string;
    public submitted: boolean = false;
    public errorLogin: boolean = false;

    /**
     * Save previous url used when token expires
     */
    public returnUrl: string = null;

    /**
     * Used to change class of body tag on ngoninit
     */
    public body_app: any;

    /**
     * Used to subscribe route query params to show errors
     */
    private sub: any;

    /**
     * Used to set key in ngx-recaptcha3
     */
    public readonly siteKey = '6Lc8pAwdAAAAAOnOOUoGZG6Yf5npCROjEXkUVsSr';

    /**
     * Used to set lang in ngx-recaptcha2
     */
    public langrc: string;

    /**
     * Used to set lang in ngx-recaptcha3
     */
    public token?: string;

    /**
     * Subscribe to router events
     */

    /**
     * Dinamic year
     */

    currentYear: number;

    /**
     * Store the language used by the user currently
     */
    public currentLang: string;

    /**
     * Shows overlay background for better mobile experience in language selection
     */
    public showLanguageMenuMobile = false;

    public loading = false

    constructor(private formBuilder: FormBuilder,
                private http: HttpClient,
                private route: ActivatedRoute,
                private router: Router,
                private modalService: NgbModal,
                private apiService: ApiService,
                @Inject(DOCUMENT) private _document,
                @Inject(WINDOW) private window: Window,
                private authService: AuthService,
                private translate: TranslateService,
                private lang: LangService,
                private trackingService: TrackingService,
                private toastr: ToastrService,
                private reCaptchaV3Service: ReCaptchaV3Service) {

        // Obtain year dinamically
        this.currentYear = (new Date()).getFullYear();

        // DOM body to add custom css class to background image in login
        this.body_app = this._document.getElementById('body-app');

        // obtain hash param of route
        this.route.params.subscribe(params => {
            this.hash = params['hash'];
        });

        // get return url from route parameters or default to '/'
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';

        // load a lang selected or lang of browser
        this.lang.loadSessionLang();

        this.langrc = this.lang.getLang();

    }

    /**
     * Used to to control when to show login, show errors that come from other routes and configure validations of the login form
     */
    ngOnInit() {
        this.currentLang = this.lang.getLang();
        // If someone returns to login after being logged in, they will close session and clean session data and will have to re-enter
        if (this.authService.islogged() && this.authService.getHash() === this.hash && this.returnUrl == '/') {
            this.authService.logout();
            this.router.navigate([`/app/login/Payyourfines/${this.hash}`]);
            this.window.location.reload();
            return false;
        }

        // close all popup modals
        this.modalService.dismissAll();


        // add custom css class to background image in login
        this.body_app.classList.add('login-bg');

        // error messages in query params
        this.sub = this.route
            .queryParams
            .subscribe(params => {

                if (params['error'] == 401) {
                    const errMsg: any = this.translate.instant('ErrorsMessages.Err401');
                    this.toastr.error(errMsg);
                    return false;
                }

                if (params['error'] == 500) {
                    const errMsg: any = this.translate.instant('ErrorsMessages.Err500');
                    this.toastr.error(errMsg);
                    return false;
                }

            });

        this.keyForm = this.formBuilder.group({
            accessKey: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(4), Validators.pattern("^[0-9]{4}$")]],
            //recaptchaReactive: new FormControl(null, Validators.required),
        });

    }

    getFines() {
        this.apiService.getFineData(`${this.hash}`)
            .subscribe((response: any) => {
                    if (response.length > 0) {
                        this.router.navigate([`app/finegroup/${this.hash}`]);
                    } else {
                        this.router.navigate([`app/viewfine/${this.hash}`]);
                        localStorage.setItem('expedientes', JSON.stringify([response['expedienteGesthispania']]));
                    }
                },
                error => {
                    this.toastr.error(error);
                });
    }

    /**
     * Used to unsubscribe route query params to show errors of user area like a session expires and remove css class for background in login
     */
    ngOnDestroy() {
        this.sub.unsubscribe();
        this.body_app.classList.remove('login-bg');
    }


    /**
     * Convenience getter for easy access to form fields
     */
    get formControls() {
        return this.keyForm.controls;
    }

    /**
     * To send the data of the entry key to the server, authservice is used
     */
    onSubmit() {
        this.submitted = true;
        this.loading = true

        // Set Value of access key if input type is number
        if (typeof this.formControls.accessKey.value === 'number') {
            let keystr = this.zeroMask(this.formControls.accessKey.value, 4);
            this.formControls.accessKey.setValue(keystr);
        }

        // stop here if form is invalid
        if (this.keyForm.invalid) {
            return;
        }

        this.reCaptchaV3Service.execute(this.siteKey, 'login', (token) => {
            if (token) {

                const observable = this.authService.login(this.hash, this.formControls.accessKey.value);

                if (observable == null) {
                    const errorsMessages: any = this.translate.instant('ErrorsMessages.OpenedSession');
                    this.toastr.error(errorsMessages);
                    return false;
                }

                observable
                    .pipe(first())
                    .subscribe(data => {
                            this.errorLogin = false;
                            setTimeout(() => {
                                this.loading = false
                            }, 1000);
                            this.getFines();
                        },
                        error => {
                            setTimeout(() => {
                                this.loading = false;
                            }, 1000);
                            this.errorLogin = true;
                            this.errorEvent(error.status, error.message);

                            if (error.status == 401) {
                                const errMsg: any = this.translate.instant('ErrorsMessages.Err401');
                                this.toastr.error(errMsg);
                                return false;
                            } else if (error.status == 500) {
                                const errMsg: any = this.translate.instant('ErrorsMessages.Err500');
                                this.toastr.error(errMsg);
                                return false;
                            } else {
                                const loginErrMsg: any = this.translate.instant('LoginPage.IncorrectKey');
                                this.toastr.error(loginErrMsg);
                                return false;
                            }

                        });

            }
        });

    }

    /**
     * Add zero mask to input type number
     */
    zeroMask(num, places) {
        let zero = places - num.toString().length + 1;
        return Array(+(zero > 0 && zero)).join("0") + num;
    }

    /**
     * Captures component event and sends event data to analytics.
     */
    myEvent(event) {
        if (this.trackingService.getPermissionTracking() == 'all' || this.trackingService.getPermissionTracking() == 'statistics') {

            this.trackingService.setHash(this.hash);

            this.trackingService.actionEvent(event, 'LOGIN');

            // Google Analytics
            let idElement: string = (event.target as Element).id;
            let typeEvent: string = event.type;
            let eventAction: string = "idelement: " + idElement + " - typeEvent: " + typeEvent;
            // (<any>window).ga('send', 'event', {
            //     eventCategory: 'Login Page Events',
            //     eventLabel: 'LoginPageEvents',
            //     eventAction: eventAction,
            //     eventValue: 10
            // });

        }

    }

    /**
     * Captures component event and sends event data to analytics.
     */
    initLoginEvent() {

        this.trackingService.setHash(this.hash);

        this.trackingService.initLoginEvent();

        // Google Analytics
        // this.router.events.subscribe(event => {
        //     if (event instanceof NavigationEnd) {
        //         // Google Analytics
        //         (<any>window).ga('send', 'event', {
        //             eventCategory: 'Login Page Events',
        //             eventLabel: 'LoginPageEvents',
        //             eventAction: 'Init Login',
        //             eventValue: 10
        //         });
        //     }
        // });


    }

    ngAfterViewInit(): void {
        // this.initLoginEvent();
    }

    /**
     * Captures component error event and sends error event data to analytics.
     */
    errorEvent(status, message) {
        if (this.trackingService.getPermissionTracking() == 'all' || this.trackingService.getPermissionTracking() == 'statistics') {

            this.trackingService.setHash(this.hash);

            this.trackingService.errorEvent(status, message);

            // Google Analytics
            let eventAction: string = "error message: " + message + " - error code status: " + status;
            // (<any>window).ga('send', 'event', {
            //     eventCategory: 'Login Page Error Events',
            //     eventLabel: 'LoginPageErrorEvents',
            //     eventAction: eventAction,
            //     eventValue: 10
            // });

        }
    }

    /**
     * To change/set lang using select header
     */
    switchLanguage(lang: string) {
        this.lang.switchLang(lang);
        this.currentLang = this.lang.getLang();
        localStorage.setItem('language', lang);
        this.window.location.reload();
        this.showLanguageMenuMobile = !this.showLanguageMenuMobile;
    }

    /**
     * Used by button toggleCollapse for mobile language menu overlay
     */
    toggleCollapse() {
        this.showLanguageMenuMobile = !this.showLanguageMenuMobile;
    }


}
